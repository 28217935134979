// extracted by mini-css-extract-plugin
export var content = "styles-module--content--11ae3";
export var dates = "styles-module--dates--a7bc1";
export var family = "styles-module--family--40c98";
export var flex = "styles-module--flex--1c33c";
export var lastItem = "styles-module--lastItem--9c4b3";
export var name = "styles-module--name--b5cfc";
export var nameTitle = "styles-module--nameTitle--75037";
export var person = "styles-module--person--0584d";
export var relation = "styles-module--relation--e1000";
export var resource = "styles-module--resource--2c2b9";
export var self = "styles-module--self--15791";
export var selfDates = "styles-module--selfDates--465a5";
export var sidebar = "styles-module--sidebar--10192";
export var spouse = "styles-module--spouse--a73bb";
export var top = "styles-module--top--96c8d";